import React, { useState } from "react"
import { FacebookShareButton, TwitterShareButton } from "react-share"
import { CopyToClipboard } from "react-copy-to-clipboard"
import Layout from "../components/layout"
import CountDown from "../components/countdown"
import valentinesLogo from "../images/matchmaker-provo-name.png"
import valentinesMatchstick from "../images/Matchmaker 21 Logo.png"
import facebookIcon from "../images/facebook.png"
import instagramIcon from "../images/instagram.png"
import linkIcon from "../images/link.png"
import twitterIcon from "../images/twitter.png"

export default function Share() {
  const [copied, setCopied] = useState(false)

  const SURVEY_CLOSE_DATE = new Date(2021, 1, 4, 23, 59, 0)

  function retakeSurvey() {
    const params = new URLSearchParams(window.location.search)
    const sessionId = params.get("sessionId")
    if (sessionId == null) {
      alert("Hmm, it looks like you haven't taking the survey yet!")
    } else {
      window.location.replace(
        `${process.env.GATSBY_MATCHMAKER_URI}?sessionId=${sessionId}`
      )
    }
  }

  return (
    <Layout>
      <div className="px-4 flex justify-center">
        <div className="max-h-full max-w-8xl mt-20 text-center flex flex-col items-center">
          <div className="text-logo-lg-mobile md:text-logo-xlg px-4">
            your answers are submitted, thanks!
          </div>
          <div className="text-answer mb-4">so...what now?</div>
          <p className="text-logo-md-mobile md:text-logo-lg">
            your matches will be sent to you via email <br /> over the next week!
          </p>
          <br/>
          <br/>
          <div className="w-screen bg-theme-red">
            <p className="text-answer theme-white">the survey is closed</p>
          </div>
          <br/>


        </div>
      </div>
    </Layout>
  )
}
